import { useState, useCallback, useEffect } from 'react';

import textos from '../../language/Lingua.json';

export const useAuth = () => {
  const [lingua, setLingua] = useState("pt-br");
  const [sala, setSala] = useState(undefined);
  const [traducao, setTraducao] = useState(textos["pt-br"]);

  const trocar = useCallback((l, s) => {
    setLingua(l);
    setTraducao(textos[l]);
    setSala(s);
    console.log("Trocou", l, s);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        lingua: l,
        sala: s
      })
    );
  }, []);

  useEffect(() => {
    console.log("pegou da aplicação")
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.lingua &&
      storedData.sala
    ) {
      trocar(storedData.lingua, storedData.sala);
    }
  }, [trocar]);

  return { lingua, traducao, trocar, sala };
};