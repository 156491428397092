import React from 'react';

import './MainHeader.css';

const MainHeader = props =>{

return (
    <nav className="navegacao">
        {props.children} 
    </nav>
);

}

export default MainHeader;