import React from 'react';
import YouTube from 'react-youtube'

import './Elemento.css';

const Elemento = props => {

    const CriaElemento = (elemento) => {
        let element;
        if(elemento !== undefined){
            let conteudo = elemento.conteudo;
            console.log(Array.isArray(conteudo))
            switch(elemento.tipo){
                case 'h1':
                    element = (<h1 className={elemento.classes}>{conteudo}</h1>);
                break;
                case 'h2':
                    element = (<h2 className={elemento.classes}>{conteudo}</h2>);
                break;
                case 'h3':
                    element = (<h3 className={elemento.classes}>{conteudo}</h3>);
                break;
                case 'h4':
                    element = (<h4 className={elemento.classes}>{conteudo}</h4>);
                break;
                case 'p':
                    element = (<p className={elemento.classes}>{conteudo}</p>);
                break;
                case 'img':
                    if(elemento.legenda){
                        element = (<div className="com-legenda">
                            <img className={elemento.classes} src={elemento.src}/>
                            <div className="legenda">{elemento.legenda}</div>
                        </div>);
                    } else {
                        element = (<img className={elemento.classes} src={elemento.src}/>);
                    }
                break;
                case 'quote':
                    element = (<blockquote className={elemento.classes}>{conteudo}</blockquote>);
                break;
                case 'a':
                    element = (<a className={elemento.classes} href={elemento.href}>{Array.isArray(conteudo) ? 
                        conteudo.map((cont, chave) => { return CriaElemento(cont); }) : conteudo}</a>); 
                break;
                case 'video':
                    element = (<YouTube videoId={elemento.video} opts={{origin:'http://localhost:3000'}}/>); 
                break;
                case 'ul':
                    element = (<ul className={elemento.classes}>
                        {conteudo.map((cont, chave) => { return CriaElemento(cont); })}
                    </ul>); 
                break;
                case 'li':
                    element = (<li className={elemento.classes}>{conteudo}</li>);
                break;  
                case 'tabela':
                    element = (<div className={elemento.classes + ' tabela'}>
                        {conteudo.map((cont, chave) => { return (<div className={cont.classes + ' row pb-2 row-cols-2'}>{CriaElemento(cont)}</div>); })}
                    </div>); 
                break;
                case 'linha':
                    element = (<React.Fragment>{conteudo.map((cont, chave) => { return (<div className={elemento.classes + ' col'}>{cont}</div>); })}</React.Fragment>); 
                break; 
                case 'carta':
                    element = (<div className={elemento.classes + ' carta'}>
                        {conteudo.map((cont, chave) => { return CriaElemento(cont); })}
                    </div>); 
                break;
                default: 
                    element = (<div className={elemento.classes}>{conteudo}</div>);
                break;
            }
        }
        return element;
    }

    return CriaElemento(props.elemento);

}

export default Elemento;