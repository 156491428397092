import React from 'react';

import './LoadingSpinner.css';

const LoadingSpinner = props => {
  return (
    <div className={`${props.asOverlay && 'loading-spinner__overlay'}`}>
         <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Enviando requisição...</span>
          </div>
    </div>
 
  );
};

export default LoadingSpinner;
