import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MainHeader from './MainHeader';

import './MainNavigation.css';

import { AuthContext } from '../../context/auth-context';

// Linguas 
import textos from '../../../language/Lingua.json';
import Lingua from './Lingua';


const MainNavigation = props => {
    let location = useLocation();
    const auth = useContext(AuthContext);

    const [abre, setAbre] = useState(false);

    const fecha = async () => {
        setAbre(false);
    }

    const abriu = async () => {
        console.log("Abriu");
        setAbre(true);
    }

    const voltar = async () => {
        auth.trocar(auth.lingua, undefined);
    }
    
    let linguas = [];
    for(var lingua in textos) {
        linguas.push(<Lingua key={lingua} qual={lingua} lingua={textos[lingua]} />)
    }

    console.log(linguas);

    let menu;
    switch(location.pathname){
        case '/':
            menu = (<div className={"menu-inicial"}><Link className={`link`} to="/"><img src={'/img/logo_museu.svg'}/></Link></div>); 
        break;
        case '/salas':
            if(auth.sala !== undefined){
                menu = (<div className={"menu-salas"}>
                <div className={`voltar transicoes`} onClick={voltar}><img src={'/img/voltar.png'}/></div>
                <Link className={`link transicoes`} to="/"><img src={'/img/logo_museu.svg'}/></Link>
                <div className="linguas">
                    {linguas}
                </div>
                </div>); 
            } else {
                menu = (<div className={"menu-salas"}>
                <Link className={`voltar transicoes`} to="/"><img src={'/img/voltar.png'}/></Link>
                <Link className={`link transicoes`} to="/"><img src={'/img/logo_museu.svg'}/></Link>
                <div className="linguas">
                    {linguas}
                </div>
                </div>);             
            }

        break;
    }

    return (
        <MainHeader>
           {menu}
        </MainHeader>
    );


}

export default MainNavigation;