import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context';


import './Lingua.css';

const Lingua = props => {
    const auth = useContext(AuthContext);
    console.log("Chave", props.qual);
    return (<div className={`lingua transicoes ${auth.lingua == props.qual ? 'ativa' : ''}`} style={{backgroundImage: `url(${props.lingua.icone})`}} onClick={() => { auth.trocar(props.qual, auth.sala) }} ></div>); 
}

export default Lingua;