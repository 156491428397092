import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

// navegacao e funcionalidades
import { useAuth } from './shared/hooks/auth-hook';
import { AuthContext } from './shared/context/auth-context';
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner';
import MainNavigation from './shared/components/Navigation/MainNavigation';
import MainFooter from './shared/components/Navigation/MainFooter';

// Páginas 
const Painel = React.lazy(() => import('./principal/pages/Painel'));
const Salas = React.lazy(() => import('./salas/pages/Salas'));

const App = () => {

  const { lingua, traducao, trocar, sala } = useAuth();
  let routes;
 
  routes = (<Switch>
    <Route path="/" exact>
      <Painel />
    </Route> 
    <Route path="/salas" exact>
      <Salas />
    </Route> 
    <Redirect to="/" />   
  </Switch>);

return (<AuthContext.Provider value={{lingua: lingua, traducao: traducao, trocar: trocar, sala: sala}}><Router>
<MainNavigation />
<main><Suspense fallback={<div className="text-center"><LoadingSpinner /></div>}>{routes}</Suspense></main>
<MainFooter />
</Router></AuthContext.Provider>);
 
};

export default App;
