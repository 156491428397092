import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './MainFooter.css';

// Elementos
import Elemento from '../Conteudo/Elemento';

import { AuthContext } from '../../context/auth-context';

const MainFooter = props => {
    let location = useLocation();
    const auth = useContext(AuthContext);

    return (<div className={"footer text-center"}>
        <Link className={`link`} to="/"><img src={'/img/marca.png'}/></Link>
        {auth.traducao.footer.map((elemento, chave) => { return (<Elemento key={chave} elemento={elemento}></Elemento>); })}
    </div>);

}

export default MainFooter;